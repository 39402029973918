import React, { useEffect, useRef, useState } from 'react';
import { SVGIMG } from '../../../../constants/game';
import { useTranslation } from '../../../../context/LanguageProvider';
import {setChatNewMessage} from "../../../../sockets/sockets";
// eslint-disable-next-line import/no-cycle,import/named

function ChatInput({
  writeGIF,
  setWriteGIF,
  avatar,
  emojiHandle,
  setEmojiHandle,
  setMessage,
  message,
  rateLimit,
  setRateLimit,
  isOpenChat,
}) {
  const messagesEndRef = useRef(null);

  useEffect(() => {
    if (isOpenChat) {
      messagesEndRef.current.scroll({
        top: 10000,
        behavior: 'smooth',
      });
    }
  }, [message.length]);

  const sendMessage = () => {
    setChatNewMessage({ type: 0, content: message, avatar });
    setEmojiHandle(false);
    setMessage('');
    setRateLimit(false);
    setTimeout(() => {
      setRateLimit(true);
    }, 1000);
  };

  const t = useTranslation();

  return (
    <div className="message__write-field">
      <form className="message__write">
        <div className="message__write-top">
          <label className="message__write-label">
            <textarea
              ref={messagesEndRef}
              className="message__write-textarea"
              value={message}
              onKeyPress={(e) => {
                if (e.key === 'Enter' && message.length >= 1) {
                  rateLimit && message.replace(/ /g, '').length >= 1 && sendMessage();
                }
              }}
              onChange={(e) =>
                e.target.value !== '\n' && e.target.value.replace(/ /g, '').length >= 1
                  ? setMessage(e.target.value)
                  : setMessage('')
              }
              maxLength="160"
              placeholder={t('Your Message')}
            />
          </label>
          <button
            type="submit"
            className="message__submit-button"
            onClick={(e) => {
              e.preventDefault();
              rateLimit && message.replace(/ /g, '').length >= 1 && sendMessage();
            }}
            style={{ opacity: message.replace(/ /g, '').length < 1 ? '0.3' : '' }}>
            {SVGIMG.messageSubmit}
          </button>
        </div>
        <div className="message__write-bottom">
          <button
            onClick={() => {
              setWriteGIF(false);
              setEmojiHandle(!emojiHandle);
            }}
            type="button"
            className="message__write-bottom-button">
            {SVGIMG.messageSmile}
          </button>

          <button
            onClick={() => {
              setEmojiHandle(false);
              setWriteGIF(!writeGIF);
            }}
            type="button"
            className="message__write-bottom-button">
            {SVGIMG.messageGIF}
          </button>
          <div className="message__write-count">{160 - message.length}</div>
        </div>
      </form>
    </div>
  );
}

export default ChatInput;
