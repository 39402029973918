import React from 'react';
import { AvatarIcons } from '../../../../constants/game';
import avatars from '../../../../assets/img/avatars/avatars';
import { useTranslation } from '../../../../context/LanguageProvider';
import {setServerAvatar} from "../../../../sockets/sockets";

function AvatarsItem({ id, selectedAvatar, closePopup }) {
  const t = useTranslation();
  return (
    <div
      className={`popup-avatars__item ${selectedAvatar === id ? 'selected' : ''}`}
      onClick={() => {
        setServerAvatar({ avatar: id });
        closePopup();
      }}>
      <div className="popup-avatars__photo">
        <img src={avatars[id]} alt="avatar" className="avatars-img" />
      </div>
      <div className="popup-avatars__name">{t(AvatarIcons[id].name)}</div>
    </div>
  );
}

export default AvatarsItem;
