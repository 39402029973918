import React from 'react';
import Popups from './Popups/Popups';
import Rocket from './Rocket';
// eslint-disable-next-line import/no-cycle
import Console from './Console/Console';

function MainMiddle({
  activePopups, toggleHandler, isOpenChat, startTime, setStartTime,
}) {
  return (

    <div className={`main__middle ${isOpenChat && 'small'}`}>
      <div className='wrapper'>
        <Rocket />
        <Console
          popupToggleHandler={toggleHandler}
          startTime={startTime}
          setStartTime={setStartTime}
      />
      </div>
      <Popups
        activePopups={activePopups}
        toggleHandler={toggleHandler}
      />
    </div>
  );
}
export default React.memo(MainMiddle);
