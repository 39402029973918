export const pushToStorage = function (key, value) {
  const sentData = {
    set: { key, value },
  };
  window.top.postMessage(JSON.stringify(sentData), '*');
};

export const getFromStorage = function (key) {
  const sentData = {
    get: key,
  };
  window.top.postMessage(JSON.stringify(sentData), '*');
};

export const isFullScreen = () => {
  if (!document.fullscreenElement && !document.mozFullScreenElement &&
      !document.webkitFullscreenElement && !document.msFullscreenElement) {
    return false;
  }
  return true;
};
