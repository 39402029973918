import React, { useState, useEffect } from 'react';
import moment from 'moment';
import PreviousButton from './PreviousButton';
import PreviousGameItem from './PreviousGameItem';
import GameHeader from '../GameHeader';
import { generateOddColor } from '../../../../../helpers/generateOddColor';
import { useSelector } from 'react-redux';
import {getPreviousRound} from "../../../../../sockets/sockets";

const PreviousGame = ({ data }) => {
  const { round_id: roundId, bets, crash_value } = data;
  const [isOpened, setOpened] = useState(false);
  const isStarted = useSelector((state) => state.gameStateReducer.is_started);
  const isCrashed = useSelector((state) => state.gameStateReducer.is_crashed);
  const [isHisteryFetched, setHisteryFetched] = useState(null);
  const date = moment(data.created_at);
  const time = date.format('HH:mm');

  const toggleHandler = () => {
    setOpened(!isOpened);
  };

  useEffect(() => {
    if (isOpened) {
      setHisteryFetched(true);
    } else if (isHisteryFetched && isCrashed) {
      setHisteryFetched(false);
    }
  }, [isCrashed, isOpened]);

  useEffect(() => {
    if (!isHisteryFetched === false && isOpened) {
      getPreviousRound();
    }
  }, [isHisteryFetched]);

  useEffect(() => {
    if (isOpened && !isStarted) {
      getPreviousRound();
      setHisteryFetched(true);
    }
  }, [isStarted]);

  return (
    <div className={`game__previous ${isOpened ? 'opened' : ''}`}>
      <PreviousButton toggleHandler={toggleHandler} />

      <div className="game__previous-main">
        <div className="game__previous-title">
          <span className="game__previous-id">ID: {roundId}</span>
          <span className="game__previous-time">{time}</span>
        </div>
        <GameHeader gamersCount={bets?.length} />
        <div className="game__list">
          {bets &&
            bets.map((item, index) => (
              <PreviousGameItem key={index} item={item} crashValue={crash_value} />
            ))}
        </div>
      </div>
    </div>
  );
};

export default PreviousGame;
