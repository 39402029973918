import React, { useState, useEffect } from 'react';
import { SVGIMG } from '../../../../constants/game';
import avatars from '../../../../assets/img/avatars/avatars';
import {setChatLike} from "../../../../sockets/sockets";

function MessageItem({ data, userId }) {
  const {
    avatar, content, id, likes, username,
  } = data;
  const [ isLiked, setIsLiked ] = useState(false);
  const [ lengthLike, setLengthLike ] = useState(0);

  const likeHandle = () => {
    setChatLike({ id });
  };

  useEffect(() => {
    setLengthLike(likes.length);
    const arg = likes.includes(userId);
    setIsLiked(arg);
  }, [ userId, data.likes.length ]);

  return (
    <div className='message__item'>
      <div className='message__user-photo'>
        <img src={avatars[avatar]} alt='avatar' className='avatars-img' />
      </div>
      <div className='message__field'>
        <div className='message__user-name'>{username}</div>
        <div className='message__body'>
          <div className='message__text' >{content}</div>
          <button
            onClick={likeHandle}
            type='button'
            className={`message__like ${isLiked && 'active'}`}
            tabIndex='-1'
          >
            <span
              className='message__like-icon '
              style={{ paddingTop: '10px' }}
            >
              {SVGIMG.chatLike}
            </span>
            <span className='message__like-count'>{lengthLike > 0 && lengthLike}</span>
          </button>
        </div>
      </div>
    </div>
  );
}


export default MessageItem;
