import React from 'react';
import { useTranslation } from '../../../../context/LanguageProvider';
import { generateOddColor } from '../../../../helpers/generateOddColor';
import {setChatNewMessage} from "../../../../sockets/sockets";
// eslint-disable-next-line import/no-cycle

function BetBody({
  cashOutOdd,
  setToolTip,
  cashOutAmount,
  currency,
  amount,
  isWon,
  crashValue,
  avatar,
  userInfoReducer,
  roundId,
}) {
  const number = generateOddColor(cashOutOdd);
  let size;
  const t = useTranslation();

  return (
    <div className="my-bets__inner-list ">
      <div
        className={`my-bets__inner-item ${
          cashOutAmount ? 'my-bets__inner-item--green' : 'my-bets__inner--empty'
        }`}>
        <div className="my-bets__inner-bet">
          <div className="my-bets__inner-bet-left">{t('BET')}</div>
          <div className="my-bets__inner-bet-right">{amount}</div>
        </div>
        {/* eslint-disable-next-line no-nested-ternary */}
        <div className={`my-bets__inner-odd wcolor-${number}`}>
          {/* eslint-disable-next-line no-nested-ternary */}
          {cashOutOdd ? `${cashOutOdd}x` : crashValue ? `${crashValue}x` : '- - -'}
        </div>
        <div className="my-bets__inner-win">
          <div className="my-bets__inner-win-left">
            {/* eslint-disable-next-line no-nested-ternary */}
            {cashOutAmount ? `${t('WIN')}` : crashValue ? `${t('Loss')}` : `${t('Pending')}`}
          </div>
          <div className="my-bets__inner-win-right">
            {cashOutAmount ? `${cashOutAmount.toFixed(2)}` : ' - - - - - -'}
          </div>
        </div>
        {cashOutOdd && (
          <div className="my-bets__inner-icon">
            <button
              onClick={() =>
                setChatNewMessage({
                  type: 1,
                  amount,
                  avatar: userInfoReducer.avatar,
                  currency,
                  cashOutAmount,
                  cashOutOdd,
                  nickname: userInfoReducer.nickname,
                  userNickname: userInfoReducer.nickname,
                  userAvatar: userInfoReducer.avatar,
                  roundId,
                })
              }
              type="button"
              className="chat-share"
              onMouseEnter={(e) => setToolTip({ coord: e.clientY - 153, text: 'Share to chat' })}
              onMouseLeave={() => setToolTip({ coord: false })}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default BetBody;
