import { getTokenFromUrl, sendResponse } from '../helpers/general';
import RWS from 'reconnecting-websocket';
import { getWebsocketUrl } from '../helpers/utils';

export const socket = new RWS(`${getWebsocketUrl()}?token=${getTokenFromUrl()}`);

// export const socket = new RWS(
//   `${window.engineURL || 'wss://rocket.nrgaming.games'}/socket?token=${
//     getTokenFromUrl() || 'cfb84aac5e9476aef8d3acde5a743457ff270b55'
//   }`,
// );

export const getRoundsHistory = () => {
  sendResponse(socket, 'game:roundHistory', { limit: 100, offset: 0 });
};

export const getRoundData = (roundId) => {
  sendResponse(socket, 'game:round', { roundId });
};

export const getLeaders = () => {
  sendResponse(socket, 'game:leaders');
};

export const getNextServerSeed = () => {
  sendResponse(socket, 'game:nextServerSeed');
};

export const betPlace = (betData) => {
  sendResponse(socket, 'profile:betPlace', betData);
};

export const getMyBets = (data) => {
  sendResponse(socket, 'profile:betsHistory', data);
};

export const getCurrentMyBets = () => {
  sendResponse(socket, 'profile:bets');
};

export const betCancel = (betId) => {
  sendResponse(socket, 'profile:betCancel', { bet_id: betId });
};

export const setServerAvatar = (data) => {
  sendResponse(socket, 'profile:update', data);
};

export const betCashOut = (cashOutData) => {
  sendResponse(socket, 'bet:checkOut', cashOutData);
};

export const setChatNewMessage = (data) => {
  sendResponse(socket, 'chat:message', data);
};

export const setChatLike = (data) => {
  sendResponse(socket, 'chat:update', data);
};

export const getBalance = () => {
  sendResponse(socket, 'profile:balance');
};

export const getPreviousRound = () => {
  sendResponse(socket, 'game:previousRound');
};
