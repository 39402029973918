import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
// eslint-disable-next-line import/no-cycle
import App from './App';

import {
  setRoundInfo,
  setOdd,
  setBetResponse,
  setGameBets,
  setCurrentMyBets,
  updateBetResponse,
  setCashOutData,
  setServerSeed,
} from './redux/actions/roundActions';

import {
  setRoundsHistory,
  setGameState,
  setLeadersList,
  setMyBetsList,
  setChatHistory,
  setOnlineUsersCount,
  setPreviousGame,
  setBalance,
  setUserInfo,
  updateChatHistory,
  updateChatLikes,
  setErrors,
  updateMyBetsList,
  setIsActiveManualSeed,
  setOptions,
  setManualSeed,
} from './redux/actions/gameActions';

import { getFromStorage } from './helpers/storageMenagement';
import { getMyBets, getRoundsHistory, socket } from './sockets/sockets';
import { string } from 'prop-types';
const Home = ({
  // eslint-disable-next-line no-shadow
  setGameState,
  setRoundsHistory,
  setRoundInfo,
  setOdd,
  setBetResponse,
  setGameBets,
  setServerSeed,
  // eslint-disable-next-line no-shadow
  setLeadersList,
  setMyBetsList,
  setCurrentMyBets,
  setChatHistory,
  setPreviousGame,
  setBalance,
  // eslint-disable-next-line no-shadow
  setUserInfo,
  updateBetResponse,
  updateChatHistory,
  setCashOutData,
  updateChatLikes,
  setErrors,
  // eslint-disable-next-line no-shadow
  setOnlineUsersCount,
  updateMyBetsList,
  myBetsListReducer,
  activeOptionsReducer,
  // eslint-disable-next-line no-shadow
  setIsActiveManualSeed,
  setOptions,
  setManualSeed,
}) => {
  const setSocketData = {
    'game:roundHistory': setRoundsHistory,
    'game:round': setRoundInfo,
    'game:state': setGameState,
    'game:odd': setOdd,
    'game:bets': setGameBets,
    'game:previousRound': setPreviousGame,
    'game:leaders': setLeadersList,
    'game:nextServerSeed': setServerSeed,
    'game:users': setOnlineUsersCount,
    'bet:checkOut': setCashOutData,
    'profile:betCancel': () => {
      getMyBets({ limit: 10, includeCurrentRound: true });
    },
    'profile:betPlace': setBetResponse,
    'profile:balance': setBalance,
    'profile:info': setUserInfo,
    'profile:update': (data) => console.log(data),
    'freebet:freebets': () => console.log(''),
    'profile:betsHistory': setMyBetsList,
    'profile:bets': (data) => {
      updateMyBetsList(data);
      setCurrentMyBets(data);
    },
    'chat:history': setChatHistory,
    'chat:message': updateChatHistory,
    'chat:update': updateChatLikes,
  };

  const encoder = new TextEncoder();

  socket.onopen = useCallback(() => {
    getRoundsHistory();
    socket.binaryType = 'arraybuffer';
    socket.onmessage = (event) => {
      const { data: pack } = event;
      const [topic, data] = JSON.parse(new TextDecoder().decode(pack));

      if (data.error) {
        setErrors(data.error);
      } else {
        setSocketData[topic](data);
      }
    };
  }, []);

  useEffect(() => {
    window.parentStorage = [];
    getFromStorage('seedType');
    getFromStorage('manualSeed');
    getFromStorage('options');

    window.addEventListener('message', (e) => {
      let data;
      if (typeof e.data === 'string') {
        data = JSON.parse(e.data);
      } else {
        data = e.data;
      }
      if (data.seedType) {
        const isActive = data.seedType.value === 'manual';
        setIsActiveManualSeed(isActive);
      } else if (data.manualSeed) {
        data.manualSeed.value && setManualSeed(data.manualSeed.value);
      } else if (data.options) {
        const optionsParsed = JSON.parse(data.options.value) || activeOptionsReducer;
        setOptions(optionsParsed);
      }
    });
  }, []);

  return (
    <>
      <App />
    </>
  );
};

const mapStateToProps = ({ myBetsListReducer, activeOptionsReducer }) => ({
  myBetsListReducer,
  activeOptionsReducer,
});

const mapDispatchToProps = {
  setRoundsHistory,
  setRoundInfo,
  setOdd,
  setGameState,
  setBetResponse,
  setGameBets,
  setLeadersList,
  setMyBetsList,
  updateMyBetsList,
  setCurrentMyBets,
  setChatHistory,
  setPreviousGame,
  setBalance,
  setUserInfo,
  updateBetResponse,
  setCashOutData,
  updateChatHistory,
  updateChatLikes,
  setErrors,
  setServerSeed,
  setOnlineUsersCount,
  setIsActiveManualSeed,
  setOptions,
  setManualSeed,
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
